import Link from 'next/link';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useStripe } from '../containers/StripeContainer';
import { AfterPayModalWrapper } from '../pagestyles/components/AfterPayModalWrapper';

const ModalAfterPay: React.FC = () => {
  // console.log('amount', amount);
  const enableAfterPay = useStripe((container) => container.enableAfterPay);
  const showAfterPayModal = useStripe((container) => container.showAfterPayModal);
  const setShowAfterPayModal = useStripe((container) => container.setShowAfterPayModal);
  return (enableAfterPay && showAfterPayModal) ? (
    <Modal show={showAfterPayModal} onHide={() => setShowAfterPayModal(false)} centered dialogClassName="after-pay">
      <AfterPayModalWrapper>
        <div className="grid-container">
          <button type="button" className="close" onClick={() => setShowAfterPayModal(false)}>
            {/* <img src="/assets/images/popup/Close.png" alt="" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" focusable="false" width="20" height="20" viewBox="0 0 30 30">
              <title>close</title>
              <line className="a" x1="1" y1="1" x2="29" y2="29" />
              <line className="a" x1="1" y1="29" x2="29" y2="1" />
            </svg>
          </button>
          <div className="row-0">
            <div className="afterpay-modal-logo">
              <img src="/assets/images/afterpay/AfterPayLogoNoBg.svg" alt="" />
            </div>
          </div>
          <div className="row-1">
            <h3 className="afterpay-modal-headline">Shop now. <span>Pay later.</span></h3>
            <h3 className="afterpay-modal-headline">Always interest-free.</h3>
          </div>
          <div className="d-flex row-2">
            <div className="process-step">
              <div className="process-step-1 process-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 72" className="process-icon shopping-cart" aria-label="Step 1">
                  <path className="c1" d="M26 55.47a27.28 27.28 0 01-4-3c-2.13-1.87-4.52-4.49-4.44-7.36a5 5 0 01.37-1.75 4.27 4.27 0 018.13.25 4.27 4.27 0 018.13-.25 5 5 0 01.37 1.75c.08 2.87-2.31 5.49-4.44 7.36a27.28 27.28 0 01-4 3" />
                  <path className="c1" fill="#000" d="M10.5 35V17a15.5 15.5 0 0131 0v18" />
                  <path className="c1" transform="rotate(90 85.5 -4.5)" d="M110.5 30.5h45.79a4.21 4.21 0 014.21 4.21v40.58a4.21 4.21 0 01-4.21 4.21H110.5v-49z" />
                </svg>
              </div>
              <div className="process-step-1 process-subheading">Add your favourites to cart</div>
            </div>
            <div className="process-step">
              <div className="process-step-2 process-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 56" className="process-icon afterpay-desktop" aria-label="Step 2">
                  <path className="c1" d="M36.5 52.49h9" />
                  <path className="c1" d="M10.43 1.5h61.14a3.93 3.93 0 013.93 3.93V50.5h-69V5.43a3.93 3.93 0 013.93-3.93z" />
                  <path d="M54.75 17.52L50.3 15l-4.51-2.59a4.48 4.48 0 00-6.71 3.89v.58a.89.89 0 00.44.77l2.1 1.2a.86.86 0 001.3-.75v-1.41a.89.89 0 011.33-.77l4.12 2.37 4.11 2.36a.88.88 0 010 1.53l-4.11 2.36-4.12 2.36a.88.88 0 01-1.33-.76v-.69a4.48 4.48 0 00-6.71-3.89l-4.51 2.59-4.45 2.55a4.49 4.49 0 000 7.78L31.7 37l4.51 2.59a4.48 4.48 0 006.71-3.89v-.58a.88.88 0 00-.44-.77l-2.1-1.2a.86.86 0 00-1.3.75v1.38a.89.89 0 01-1.33.77l-4.12-2.37-4.11-2.36a.88.88 0 010-1.53l4.11-2.36 4.12-2.36a.88.88 0 011.33.76v.69a4.48 4.48 0 006.71 3.89l4.51-2.59 4.45-2.55a4.49 4.49 0 000-7.75z" />
                  <rect className="c1" width="79" height="4" x="1.5" y="50.49" rx="2" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 82" className="process-icon afterpay-mobile" aria-label="Step 2">
                  <rect className="c1" width="79" height="49" x="86.5" y="16.5" rx="4.21" transform="rotate(90 76 -9)" />
                  <path className="c1" d="M1.49 10.5h49" />
                  <path className="c1" d="M1.49 75.5h49" />
                  <path className="c1" d="M21.49 6.5h9" />
                  <path d="M39.75 32.52L35.3 30l-4.51-2.59a4.48 4.48 0 00-6.71 3.89v.58a.89.89 0 00.44.77l2.1 1.2a.86.86 0 001.3-.75v-1.41a.89.89 0 011.33-.77l4.12 2.37 4.11 2.36a.88.88 0 010 1.53l-4.11 2.36-4.12 2.36a.88.88 0 01-1.33-.76v-.69a4.48 4.48 0 00-6.71-3.89l-4.51 2.59-4.45 2.55a4.49 4.49 0 000 7.78L16.7 52l4.51 2.59a4.48 4.48 0 006.71-3.89v-.58a.88.88 0 00-.44-.77l-2.1-1.2a.86.86 0 00-1.3.75v1.38a.89.89 0 01-1.33.77l-4.12-2.37-4.11-2.36a.88.88 0 010-1.53l4.11-2.36 4.12-2.36a.88.88 0 011.33.76v.69a4.48 4.48 0 006.71 3.89l4.51-2.59 4.45-2.55a4.49 4.49 0 000-7.75z" />
                </svg>
              </div>
              <div className="process-step-2 process-subheading">Select Afterpay at checkout</div>
            </div>
            <div className="process-step">
              <div className="process-step-3 process-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 56" className="process-icon sign-up-desktop" aria-label="Step 3">
                  <path className="c1" d="M52.51 28.12l4.43 4.45L65.5 24" />
                  <circle className="c1" cx="31" cy="26" r="14.5" />
                  <circle className="c1" cx="31" cy="21.8" r="6.09" />
                  <path className="c1" d="M20.92 36.42a8.43 8.43 0 018-5.8h4.2a8.45 8.45 0 018 5.8M36.5 52.49h9" />
                  <path className="c1" d="M10.43 1.5h61.14a3.93 3.93 0 013.93 3.94V50.5h-69V5.45a3.93 3.93 0 013.93-3.93z" />
                  <rect className="c1" width="79" height="4" x="1.5" y="50.49" rx="2" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 82" className="process-icon sign-up-mobile" aria-label="Step 3">
                  <rect className="c1" width="79" height="49" x="86.5" y="16.5" rx="4.21" transform="rotate(90 76 -9)" />
                  <path className="c1" d="M21.46 58.06l4.43 4.45 8.55-8.58M1.5 10.5h48.99M1.5 75.5h48.99M21.5 6.5h9" />
                  <circle className="c1" cx="26" cy="34" r="14.5" /><circle className="c1" cx="26" cy="29.8" r="6.09" />
                  <path className="c1" d="M15.93 44.42a8.43 8.43 0 018-5.8h4.2a8.45 8.45 0 018 5.79" />
                </svg>
              </div>
              <div className="process-step-3 process-subheading">Log into or create your Afterpay account, instant approval decision</div>
            </div>
            <div className="process-step">
              <div className="process-step-4 process-icon-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="14 7 79 79" className="process-icon four-payments" aria-label="Step 4">
                  <path className="c1" d="M50,15.5A34.5,34.5,0,0,0,15.5,50H50Z" />
                  <path className="c1" d="M15.5,50A34.5,34.5,0,0,0,50,84.5V50Z" />
                  <path className="c1" d="M50,84.5h0A34.5,34.5,0,0,0,84.5,50H50Z" />
                  <path className="c1" d="M57,8.5h0V43h34.5A34.5,34.5,0,0,0,57,8.5Z" />
                </svg>
              </div>
              <div className="process-step-4 process-subheading">Your purchase will be split into 4 payments, payable every 2 weeks</div>
            </div>
          </div>
          <div className="row-3">
            <div className="disclaimer"><br /><br />Late fees and additional eligibility criteria apply. The first payment may be due at the time of purchase.<br />For complete terms visit&nbsp;
              <Link href="https://www.afterpay.com/en-AU/terms-of-service" passHref>
                <a href="https://www.afterpay.com/en-AU/terms-of-service" target="_blank" rel="noopener noreferrer" className="disclaimer-link">afterpay.com/en-AU/terms-of-service</a>
              </Link>
            </div>
          </div>
        </div>
      </AfterPayModalWrapper>
    </Modal>
  ) : null;
};
export default ModalAfterPay;
