/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  afterPayBlue, nHaasTx55, nHaasTx65, nHaas55,
} from '../../../styles/theme';

export const AfterPayModalWrapper = styled.div`
  padding: 0 24px;
  background-color: ${afterPayBlue};

  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    background: none;
    border: none;
    padding: 0;
    z-index: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .a{
    fill:none;
    stroke:#808284;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:2px;
  }

  .row-0 {
    padding-top: 45px;
  }

  .row-1 {
    grid-area: headline;
    padding: 3% 0 8% 0;
  }

  .row-3 {
    padding: 8% 0 40px 0;
  }

  h3 {
    font-family: ${nHaasTx55};
    font-size: 44px;
    font-weight: bolder;
    line-height: 1.1;
    margin-bottom
  }

  .afterpay-modal-logo {
    img {
      width: 100%;
      max-width: 275px;
      height: auto;
    }
  }

  .process-step {
    width: 25%;
  }

  .process-icon-container {
    height: 100%;
    max-height: 95px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .afterpay-mobile, .sign-up-mobile {
      display: none;
    }

    svg {
      width: auto;
      height: auto;
      max-width: 110px;
      max-height: 95px;

      .c1 {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
      }
    }
  }

  .process-subheading {
    padding: 10% 3% 0 3%;
    line-height: 1.25;
    font-family: ${nHaasTx65};
  }

  .disclaimer {
    font-size: 12px;
    // font-family: ${nHaas55};
    font-weight: 500;
    font-family: sans-serif;

    a {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 992px) {
    text-align: left;
    padding: 30px;

    .row-0 {
      padding-top: 0;
    }

    .row-1 {
      padding: 20px 0 8% 0;
    }
    
    .row-2 {
      flex-direction: column;
    }

    .row-3 {
      padding: 8% 0 20px 0;
    }

    h3 {
      font-size: 36px;
    }

    .afterpay-modal-logo {
      img {
        max-width: 200px;
      }
    }

    .process-step {
      display: flex;
      width: 100%;
    }

    .process-icon-container {
      padding: 12px 0;

      svg {
        width: 41px;
        max-height: 65px;
      }
    }

    .process-subheading {
      padding: 6px 0 6px 12px;
      align-self: center;
    }

    .afterpay-desktop, .sign-up-desktop {
      display: none;
    }

    .afterpay-mobile, .sign-up-mobile {
      display: block !important;
    }
  }
`;
